import countries from '../assets/json/countries.json';

const UPS_FILTER_BY_COUNTRY_NAME = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'France',
  'Lithuania',
  'Luxembourg',
  'Latvia',
  'Germany',
  'Poland',
  'Netherlands',
  'Romania',
  'Slovenia',
  'Slovakia',
  'Hungary',
  'Italy',
];
const ENDPOINTS = {
  genToken: '/get-token',
  getLocations: '/get-locations',
};

const upsCountries = countries.filter((country) =>
  UPS_FILTER_BY_COUNTRY_NAME.includes(country.name)
);
const UPS_URL = 'https://onlinetools.ups.com';
const UPS_URL_CIE = 'https://wwcie.ups.com';

const getBasicAuthStringByCredentials = ({ user, password } = null) => {
  const auth = Buffer.from(`${user}:${password}`).toString('base64');
  return `Basic ${auth}`;
};
const getBearerAuthStringByAccessToken = (token) => {
  return `Bearer ${token}`;
};
const createNearestAccessPointsPayload = (
  addressLine,
  city,
  postalCode,
  countryCode,
  state,
  locale = 'en_US'
) => {
  const payload = {
    LocatorRequest: {
      Request: {
        RequestAction: 'Locator',
      },
      OriginAddress: {
        AddressKeyFormat: {
          AddressLine: addressLine,
          PoliticalDivision2: city,
          PoliticalDivision1: state,
          PostcodePrimaryLow: postalCode,
          CountryCode: countryCode,
        },
      },
      Translate: {
        Locale: locale,
      },
      LocationSearchCriteria: {
        MaximumListSize: '10',
        SearchRadius: '150',
      },

      UnitOfMeasurement: { Code: 'KM' },
      SortCriteria: { SortType: { SortType: '01' } },
    },
  };
  return payload;
};

export {
  ENDPOINTS,
  UPS_URL,
  UPS_URL_CIE,
  upsCountries,
  getBasicAuthStringByCredentials,
  getBearerAuthStringByAccessToken,
  createNearestAccessPointsPayload,
};
