import { useContext, ref } from '@nuxtjs/composition-api';
import { useCookies } from '@/node_modules/@vueuse/integrations/useCookies';
import { ENDPOINTS, upsCountries } from '~/helpers/ups';

const useUPS = () => {
  const { $axios } = useContext();
  const token = ref(null);
  const cookies = useCookies(['ups-token']);
  const locations = ref([]);

  const isLocationsLoading = ref(false);

  const generateTokenExpireDate = (tokenData) => {
    const expireDateInSeconds =
      new Date(parseInt(tokenData.issued_at, 10)).valueOf() +
      parseInt(tokenData.expires_in, 10) * 1000;
    return new Date(expireDateInSeconds);
  };

  const generateToken = async () => {
    token.value = cookies.get('ups-token');
    if (token.value) return;
    const formData = {
      grant_type: 'client_credentials',
    };
    try {
      const { data } = await $axios.post(
        `/ups${ENDPOINTS.genToken}`,
        new URLSearchParams(formData).toString(),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      );
      token.value = data.access_token;
      cookies.set('ups-token', data.access_token, {
        expires: generateTokenExpireDate(data),
      });
    } catch (e) {
      console.error(e);
    }
  };
  const getLocations = async (addressInfo) => {
    const countryCode = upsCountries.find(
      (upsCountry) => upsCountry.name === addressInfo.country
    ).code;
    isLocationsLoading.value = true;
    try {
      const { data } = await $axios.post(`/ups${ENDPOINTS.getLocations}`, {
        token: token.value,
        data: {
          addressLine: addressInfo.addressLine,
          city: addressInfo.city,
          postalCode: addressInfo.postalCode,
          countryCode,
          state: addressInfo.state,
        },
      });
      locations.value = data.LocatorResponse.SearchResults.DropLocation.map(
        (location) => {
          return {
            city: location.AddressKeyFormat.PoliticalDivision2,
            postcode: location.AddressKeyFormat.PostcodePrimaryLow,
            street: location.AddressKeyFormat.AddressLine,
            house_number: '',
          };
        }
      );
    } catch (e) {
      console.error(e);
      locations.value = [];
    } finally {
      isLocationsLoading.value = false;
    }
  };

  return {
    generateToken,
    getLocations,
    locations,
    isLocationsLoading,
    upsCountries,
  };
};

export default useUPS;
